import SvgJsx from "./svg/svg-jsx";

export default function PopupMenuOption({
     isDisplay = true
    ,onClick 
    ,icon
    ,icontype="fill"
    ,title
    ,children=<></>
    ,colorClass=`bg-gray-900 hover:bg-gray-700 hover:text-gray-100 text-gray-200 `
    
}) {
    return (<>
    
    {(isDisplay) &&
    <div>
        <div className="menu-item">
                                
          <button 
            className={`${colorClass}
            cursor-pointer rounded-md flex items-center content-center justify-left w-full px-2 py-2 text-sm leading-5 text-left `}
            onClick={onClick}
            tabIndex={0} 
          > 
            <div className="flex-0 justify-left text-sm mr-1">
              <SvgJsx
                type={icontype}
                icon={icon}
                className={` h-4 w-4`}
                title={title}
              />
            </div>
            <div className="ml-2 flex-0 ">
              {title}
            </div>
          </button>
        </div>
        {children}
    </div>
    }
    
    </>)
}